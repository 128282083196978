import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentInfo: null,
    dataExport: null,
    allFactEthnics: [],
    allFactDisabilities: [],
    allFactReligions: [],
    allFactProvinces: [],
    allFactDistricts: [],
    allFactWards: [],
    allFactDistrictsContact: [],
    allFactWardsContact: [],
    allFactDistrictsPermanentResidence: [],
    allFactWardsPermanentResidence: [],
    allFactNationalities: [],
    curriculumVitaeLearnings: [],
    curriculumVitaeScholarships: [],
    curriculumVitaeAwards: [],
    curriculumVitaeDisciplines: [],
    curriculumVitaePolicies: [],
    curriculumVitaeAllowances: [],
  },
  getters: {
    studentInfo: state => state.studentInfo,
    dataExport: state => state.dataExport,
    allFactEthnics: state => state.allFactEthnics,
    allFactDisabilities: state => state.allFactDisabilities,
    allFactReligions: state => state.allFactReligions,
    allFactProvinces: state => state.allFactProvinces,
    allFactDistricts: state => state.allFactDistricts,
    allFactWards: state => state.allFactWards,
    allFactDistrictsContact: state => state.allFactDistrictsContact,
    allFactWardsContact: state => state.allFactWardsContact,
    allFactDistrictsPermanentResidence: state => state.allFactDistrictsPermanentResidence,
    allFactWardsPermanentResidence: state => state.allFactWardsPermanentResidence,
    allFactNationalities: state => state.allFactNationalities,
    curriculumVitaeLearnings: state => state.curriculumVitaeLearnings,
    curriculumVitaeScholarships: state => state.curriculumVitaeScholarships,
    curriculumVitaeAwards: state => state.curriculumVitaeAwards,
    curriculumVitaeDisciplines: state => state.curriculumVitaeDisciplines,
    curriculumVitaePolicies: state => state.curriculumVitaePolicies,
    curriculumVitaeAllowances: state => state.curriculumVitaeAllowances,
  },
  mutations: {
    SET_STUDENT_INFO: (state, data) => {
      state.studentInfo = data
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
    SET_FACT_ETHNICS: (state, data) => {
      state.allFactEthnics = data
    },
    SET_FACT_DISABILITIES: (state, data) => {
      state.allFactDisabilities = data
    },
    SET_FACT_RELIGIONS: (state, data) => {
      state.allFactReligions = data
    },
    SET_FACT_PROVINCES: (state, data) => {
      state.allFactProvinces = data
    },
    SET_FACT_NATIONALITIES: (state, data) => {
      state.allFactNationalities = data
    },
    SET_DISTRICT: (state, data) => {
      state.allFactDistricts = data
    },
    SET_WARD: (state, data) => {
      state.allFactWards = data
    },
    SET_DISTRICT_CONTACT: (state, data) => {
      state.allFactDistrictsContact = data
    },
    SET_WARD_CONTACT: (state, data) => {
      state.allFactWardsContact = data
    },
    SET_DISTRICT_PERMANENT_RESIDENCE: (state, data) => {
      state.allFactDistrictsPermanentResidence = data
    },
    SET_WARD_PERMANENT_RESIDENCE: (state, data) => {
      state.allFactWardsPermanentResidence = data
    },
    SET_CURRICULUM_VITAE_LEARNINGS: (state, data) => {
      state.curriculumVitaeLearnings = data
    },
    SET_CURRICULUM_VITAE_SCHOLARSHIPS: (state, data) => {
      state.curriculumVitaeScholarships = data
    },
    SET_CURRICULUM_VITAE_AWARDS: (state, data) => {
      state.curriculumVitaeAwards = data
    },
    SET_CURRICULUM_VITAE_DISCIPLINES: (state, data) => {
      state.curriculumVitaeDisciplines = data
    },
    SET_CURRICULUM_VITAE_POLICIES: (state, data) => {
      state.curriculumVitaePolicies = data
    },
    SET_CURRICULUM_VITAE_ALLOWANCES: (state, data) => {
      state.curriculumVitaeAllowances = data
    },
  },
  actions: {
    async getFactEthnics({ commit }) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactEthnics')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FACT_ETHNICS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getFactDisabilities({ commit }) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactDisabilities')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_FACT_DISABILITIES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getFactReligions({ commit }, params) {
      try {
        const response = await axios.get('uni/factReligions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_FACT_RELIGIONS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getFactProvinces({ commit }, params) {
      try {
        const response = await axios.get('uni/factProvinces', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_FACT_PROVINCES', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getFactNationalities({ commit }, params) {
      try {
        const response = await axios.get('uni/factNationalities', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_FACT_NATIONALITIES', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentInfo({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            info,
            learnings,
            scholarships,
            awards,
            disciplines,
            policies,
            allowances,
          } = data.data
          commit('SET_STUDENT_INFO', info)
          commit('SET_CURRICULUM_VITAE_LEARNINGS', learnings)
          commit('SET_CURRICULUM_VITAE_SCHOLARSHIPS', scholarships)
          commit('SET_CURRICULUM_VITAE_AWARDS', awards)
          commit('SET_CURRICULUM_VITAE_DISCIPLINES', disciplines)
          commit('SET_CURRICULUM_VITAE_POLICIES', policies)
          commit('SET_CURRICULUM_VITAE_ALLOWANCES', allowances)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDistrict({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactDistricts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DISTRICT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getWard({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactWards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_WARD', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDistrictContact({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactDistricts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DISTRICT_CONTACT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getWardContact({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactWards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_WARD_CONTACT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDistrictPermanentResidence({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactDistricts', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DISTRICT_PERMANENT_RESIDENCE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getWardPermanentResidence({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info/selectAllFactWards', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_WARD_PERMANENT_RESIDENCE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExport({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/student-info/export-ho-so',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateStudentInfo({ commit }, params) {
      try {
        const response = await axios.put(`uni/student-info/${params.student_id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
