<template src="./index.html" />

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BContainer, BImg,
  BFormInput, BFormDatepicker, BFormGroup, BForm, BFormFile, BTab, BTabs, BOverlay, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { HAS_LEARN_PARTY } from '@/const/selection'
import { Flag } from '@/const/flag'
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BContainer,
    BImg,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BForm,
    vSelect,
    BFormFile,
    BTab,
    BTabs,
    BOverlay,
    VueGoodTable,
    BFormSelect,
    BPagination,
    flatPickr,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      mainProps: {
        fluidGrow: true,
        height: 200,
      },
      avatar: [],
      ulrAvatar: '',
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scholarshipColumns: [
        {
          label: 'Khóa học',
          field: 'course',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập khóa học',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học kỳ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học bổng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học bổng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      awardColumns: [
        {
          label: 'Tên khen thưởng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên khen thưởng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      disciplineColumns: [
        {
          label: 'Tên kỷ luật',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kỷ luật',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      policyColumns: [
        {
          label: 'Đối tượng trợ cấp',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập đối tượng trợ cấp',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      allowanceColumns: [
        {
          label: 'Trợ cấp sư phạm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập trợ cấp sư phạm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      gendersSelect: [],
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: FlatpickrLanguages.vn,
        static: false,
        // minDate: 'today',
      },
    }
  },

  async created() {
    this.isLoading = true
	  try {
      await this.getInitData()
      await this.begin()
      this.gendersSelect = this.genders.map(item => ({ value: item.id, label: item.name }))
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      curriculumVitaeScholarships: 'curriculumVitae/curriculumVitaeScholarships',
      curriculumVitaeAwards: 'curriculumVitae/curriculumVitaeAwards',
      curriculumVitaeDisciplines: 'curriculumVitae/curriculumVitaeDisciplines',
      curriculumVitaePolicies: 'curriculumVitae/curriculumVitaePolicies',
      curriculumVitaeAllowances: 'curriculumVitae/curriculumVitaeAllowances',
      genders: 'dropdown/genders',
      studentInfo: 'curriculumVitae/studentInfo',
      allFactDisabilities: 'curriculumVitae/allFactDisabilities',
      dataExport: 'curriculumVitae/dataExport',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      allFactDisabilities: 'curriculumVitae/allFactDisabilities',
      allFactReligions: 'curriculumVitae/allFactReligions',
      allFactProvinces: 'curriculumVitae/allFactProvinces',
      allFactDistricts: 'curriculumVitae/allFactDistricts',
      allFactWards: 'curriculumVitae/allFactWards',
      allFactDistrictsContact: 'curriculumVitae/allFactDistrictsContact',
      allFactWardsContact: 'curriculumVitae/allFactWardsContact',
      allFactDistrictsPermanentResidence: 'curriculumVitae/allFactDistrictsPermanentResidence',
      allFactWardsPermanentResidence: 'curriculumVitae/allFactWardsPermanentResidence',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
    }),
    hasLearnPartySelect() {
      return HAS_LEARN_PARTY
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    classId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.classId : null
    },
  },
  methods: {
    // handle even
    ...mapActions({
      uploadAvatar: 'student/uploadAvatar',
      getStudentInfo: 'curriculumVitae/getStudentInfo',
      getDataExport: 'curriculumVitae/downloadExport',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getFactDisabilities: 'curriculumVitae/getFactDisabilities',
      getFactReligions: 'curriculumVitae/getFactReligions',
      getFactProvinces: 'curriculumVitae/getFactProvinces',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      updateStudentInfo: 'curriculumVitae/updateStudentInfo',
      getDistrict: 'curriculumVitae/getDistrict',
      getWard: 'curriculumVitae/getWard',
      getDistrictContact: 'curriculumVitae/getDistrictContact',
      getWardContact: 'curriculumVitae/getWardContact',
      getDistrictPermanentResidence: 'curriculumVitae/getDistrictPermanentResidence',
      getWardPermanentResidence: 'curriculumVitae/getWardPermanentResidence',
      getGenders: 'dropdown/getGenders',
      getLinkAvatar: 'student/getLinkAvatar',
    }),

    async onProvinceChange(event) {
      this.isLoading = true
      try {
        this.studentInfo.province_code = event
        await this.getDistrict({ province_code: event })
        this.studentInfo.district_code = null
        this.studentInfo.ward_code = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code = event
        await this.getWard({ province_code: this.studentInfo.province_code, district_code: event })
        this.studentInfo.ward_code = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onProvinceContactChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.province_code_contact = event
        await this.getDistrictContact({ province_code: event })
        this.studentInfo.district_code_contact = null
        this.studentInfo.ward_code_contact = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictContactChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code_contact = event
        await this.getWardContact({ province_code: this.studentInfo.province_code_contact, district_code: event })
        this.studentInfo.ward_code_contact = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onProvincePermanentResidenceChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.province_code_permanent_residence = event
        await this.getDistrictPermanentResidence({ province_code: event })
        this.studentInfo.district_code_permanent_residence = null
        this.studentInfo.ward_code_permanent_residence = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDistrictPermanentResidenceChange(event) {
      this.isLoading = true
	    try {
        this.studentInfo.district_code_permanent_residence = event
        await this.getWardPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence, district_code: event })
        this.studentInfo.ward_code_permanent_residence = null
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async onSubmit(event) {
      this.isLoading = true
	    try {
        event.preventDefault()
        const res = await this.updateStudentInfo(this.studentInfo)

        if (res.isSuccessful) {
          this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đang bị trống!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

	    try {
        files.forEach(file => formData.append('files', file))
        const response = await this.uploadAvatar(formData)
        if (response.isSuccessful) {
          this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
          await this.getInitData()
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
        }
        this.$refs.file.reset()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getInitData() {
      this.isLoading = true
      try {
        await this.getStudentInfo({ studentId: this.user.studentId, classId: this.classId })
        if (!this.studentInfo.path_avatar) {
          this.ulrAvatar = require('@/assets/images/avatars/default_avatar.jpg')
        } else {
          const resLink = await this.getLinkAvatar(this.studentInfo.path_avatar)
          if (resLink.isSuccessful) {
            this.ulrAvatar = resLink.data
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async begin() {
      this.isLoading = false
      try {
        await Promise.all([
          this.getFactEthnics(),
          this.getFactDisabilities(),
          this.getFactReligions({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
          this.getFactProvinces({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
          this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
          this.getGenders({}),
          this.getDistrict({ province_code: this.studentInfo.province_code }),
          this.getWard({
            province_code: this.studentInfo.province_code,
            district_code: this.studentInfo.district_code,
          }),
          this.getDistrictContact({ province_code: this.studentInfo.province_code_contact }),
          this.getWardContact({ province_code: this.studentInfo.province_code_contact, district_code: this.studentInfo.district_code_contact }),
          this.getDistrictPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence }),
          this.getWardPermanentResidence({ province_code: this.studentInfo.province_code_permanent_residence, district_code: this.studentInfo.district_code_permanent_residence }),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
